<template>
  <!-- Modal Form -->
  <b-modal
    id="modal-form"
    title="Tambah Jadwal Libur"
    ok-title="Simpan"
    cancel-title="Batal"
    size="md"
    @ok="modalOnSubmit"
    @hidden="modalOnHide"
    @show="modalOnShow"
  >
    <b-form @submit.stop.prevent="formOnSubmit">
      <!-- Doctor Select -->
      <template v-if="!hasDoctor">
        <b-form-group
          id="input-group-doctor"
          label="Pilih Dokter:"
          label-for="input-doctor"
        >
          <treeselect
            v-model="form.doctor_id"
            :multiple="false"
            @select="selectDoctorOnChange"
            :options="doctors"
          />
          <small class="text-danger">{{ error.doctor_id }}</small>
        </b-form-group>
      </template>

      <!-- <div class="row no-gutters mx-n1"> -->

      <!-- Date Picker -->
      <!-- <div class="col-lg-6 col-12 px-1">
          <b-form-group
            id="input-group-start-date"
            label="Dari Tgl:"
            label-for="input-start-date"
          >
            <b-form-datepicker
              id="input-start-date"
              v-model="form.start_date"
              placeholder="Pilih Tanggal"
              :date-disabled-fn="disableDate"
              locale="id"
            ></b-form-datepicker>
            <small class="text-danger">{{ error.start_date }}</small>
          </b-form-group> 
          <b-form-group
            id="input-group-date"
            label="Dari Tgl:"
            label-for="input-date"
          >

            <b-input-group>
              <b-input-group-prepend>
                <b-form-datepicker
                  locale="id"
                  placeholder="Tanggal"
                  id="input-date"
                  v-model="form.start_date"
                  button-only
                  :date-disabled-fn="disableDate"
                  aria-controls="example-input"
                ></b-form-datepicker>
              </b-input-group-prepend>
              <b-form-input
                id="input-date"
                v-model="form.start_date"
                type="text"
                placeholder="YYYY-MM-DD"
                :date-disabled-fn="disableDate"
                @input="formaterDate"
              ></b-form-input>
              <small class="text-danger">{{ error.start_date }}</small>
            </b-input-group>
          </b-form-group>
        </div> -->

      <!-- Date Picker -->
      <!-- <div class="col-lg-6 col-12 px-1">
          <b-form-group
            id="input-group-end-date"
            label="Sampai Tgl:"
            label-for="input-end-date"
          >
            <b-form-datepicker
              id="input-end-date"
              v-model="form.end_date"
              placeholder="Pilih Tanggal"
              :date-disabled-fn="disableDate"
              locale="id"
            ></b-form-datepicker>
            <small class="text-danger">{{ error.end_date }}</small>
          </b-form-group>
        </div> -->

      <!-- </div> -->

      <div class="row no-gutters mx-n1">
        <!-- Date Picker -->
        <div class="col-lg-6 col-12 px-1">
          <!-- <b-form-group
            id="input-group-start-date"
            label="Dari Tgl:"
            label-for="input-start-date"
          >
            <b-form-datepicker
              id="input-start-date"
              v-model="form.start_date"
              placeholder="Pilih Tanggal"
              :date-disabled-fn="disableDate"
              locale="id"
            ></b-form-datepicker>
            <small class="text-danger">{{ error.start_date }}</small>
          </b-form-group> -->
          <b-form-group
            id="input-group-date"
            label="Dari Tgl:"
            label-for="input-date"
          >
            <b-input-group>
              <b-input-group-prepend>
                <b-form-datepicker
                  locale="id"
                  placeholder="Tanggal"
                  id="input-date"
                  v-model="form.start_date"
                  button-only
                  :date-disabled-fn="disableDate"
                  aria-controls="example-input"
                ></b-form-datepicker>
              </b-input-group-prepend>
              <b-form-input
                id="input-date"
                v-model="form.start_date"
                type="text"
                placeholder="YYYY-MM-DD"
                :date-disabled-fn="disableDate"
                @input="formaterDate"
              ></b-form-input>
              <small class="text-danger">{{ error.start_date }}</small>
            </b-input-group>
          </b-form-group>
        </div>

        <!-- Date Picker -->
        <div class="col-lg-6 col-12 px-1">
          <!-- <b-form-group
            id="input-group-end-date"
            label="Sampai Tgl:"
            label-for="input-end-date"
          >
            <b-form-datepicker
              id="input-end-date"
              v-model="form.end_date"
              placeholder="Pilih Tanggal"
              :date-disabled-fn="disableDate"
              locale="id"
            ></b-form-datepicker>
            <small class="text-danger">{{ error.end_date }}</small>
          </b-form-group> -->

          <b-form-group
            id="input-group-date"
            label="Sampai Tgl:"
            label-for="input-date-end"
          >
            <b-input-group>
              <b-input-group-prepend>
                <b-form-datepicker
                  locale="id"
                  placeholder="Tanggal"
                  v-model="form.end_date"
                  button-only
                  :date-disabled-fn="disableDate"
                  aria-controls="example-input"
                ></b-form-datepicker>
              </b-input-group-prepend>
              <b-form-input
                id="input-date-end"
                v-model="form.end_date"
                type="text"
                placeholder="YYYY-MM-DD"
                :date-disabled-fn="disableDate"
                @input="formaterDateEnd"
              ></b-form-input>
              <small class="text-danger">{{ error.end_date }}</small>
            </b-input-group>
          </b-form-group>

          <!-- <b-form-group
            id="input-group-date"
            label="Dari Tgl:"
            label-for="input-date-end"
          >

            <b-input-group>
              <b-input-group-prepend>
                <b-form-datepicker
                  locale="id"
                  placeholder="Tanggal"
                  v-model="form.end_date"
                  button-only
                  :date-disabled-fn="disableDate"
                  aria-controls="example-input"
                ></b-form-datepicker>
              </b-input-group-prepend>
              <b-form-input
                id="input-date-end"
                v-model="form.end_date"
                type="text"
                placeholder="YYYY-MM-DD"
                :date-disabled-fn="disableDate"
                @input="formaterDateEnd"
              ></b-form-input>
              <small class="text-danger">{{ error.end_date }}</small>
            </b-input-group>
          </b-form-group>-->
        </div>
      </div>

      <div class="form-group">
        <div>
          <i class="fas fa-fw fa-exclamation"></i>Kosongkan input
          <em class="font-weight-bold">sampai tanggal</em> jika hanya libur 1
          hari
        </div>
      </div>

      <!-- Notes input -->
      <b-form-group id="input-group-notes">
        <label for="input-notes"
          >Keterangan: <em class="text-muted">opsional</em></label
        >
        <b-form-textarea
          id="input-notes"
          v-model="form.notes"
          placeholder="Keterangan"
          rows="4"
          max-rows="8"
        ></b-form-textarea>
        <small class="text-danger">{{ error.notes }}</small>
      </b-form-group>
    </b-form>
  </b-modal>
</template>

<script>
import module from "@/core/modules/CrudModule.js";
import validation from "@/core/modules/ValidationModule.js";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";

export default {
  components: {
    Treeselect,
  },

  props: {
    form: Object,
    route: String,
    editTarget: String,
    hasDoctor: Boolean,
  },

  data() {
    return {
      // Error
      error: {
        doctor_id: "",
        start_date: "",
        end_date: "",
        notes: "",
      },
      // Options
      doctors: [],
      // Other
      holidayDates: [],
    };
  },

  methods: {
    formaterDate(val) {
      if (val.length == 4) {
        this.form.start_date = this.form.start_date + "-";
      }
      if (val.length == 7) {
        this.form.start_date = this.form.start_date + "-";
      }
    },

    formaterDateEnd(val) {
      if (val.length == 4) {
        this.form.end_date = this.form.end_date + "-";
      }
      if (val.length == 7) {
        this.form.end_date = this.form.end_date + "-";
      }
    },

    resetHolidayDates() {
      this.holidayDates = [];
    },

    async selectDoctorOnChange(evt) {
      this.resetHolidayDates();
      this.holidayDates = await module.get(
        `holidays/get-date-by-doctor/${evt.id}`
      );
    },

    async datePickerOnClick() {
      this.resetHolidayDates();
      this.holidayDates = await module.get(
        `holidays/get-date-by-doctor/${this.$route.params.id}`
      );
    },

    disableDate(ymd, date) {
      if (this.holidayDates.length != 0) {
        const day = date.getDate();
        for (let i = 0; i < this.holidayDates.length; i++) {
          if (ymd === this.holidayDates[i].start_date.split(" ")[0]) {
            return true;
          }
        }
      }
    },

    async getDoctorsOption() {
      let response = await module.setDoctorTreeSelect("doctors");
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.doctors = response.data;
        this.doctors.unshift({
          label: "Pilih Dokter",
          id: "",
          isDisabled: true,
        });
      }
    },

    async formOnSubmit() {
      let route;
      this.editTarget == ""
        ? (route = this.route)
        : (route = `${this.route}/${this.editTarget}`);
      let response = await module.submit(this.form, route);
      // Check Response
      if (response.state == "error") {
        // Validation Error
        this.error = validation.setValidationError(
          this.error,
          response.error.errors
        );
      } else {
        // Success
        Swal.fire(response.success.title, response.success.message, "success");
        // Hide Modal
        this.$bvModal.hide("modal-form");
        // Refresh Table
        this.$emit("formOnSubmit");
      }
    },

    modalOnSubmit(evt) {
      evt.preventDefault();
      this.formOnSubmit();
    },

    modalOnShow() {
      if (this.hasDoctor) this.datePickerOnClick();
    },

    modalOnHide(evt) {
      this.error = validation.clearValidationError(this.error);
      this.form = validation.clearForm(this.form);
      // Reset Form & Edit Target
      delete this.form["_method"];
      if (this.hasDoctor) {
        this.form.doctor_id = this.$route.params.id;
      }
    },
  },

  mounted() {
    if (!this.hasDoctor) this.getDoctorsOption();
  },
};
</script>

<style>
</style>